var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import FormLabel from './FormLabel';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n\n  input {\n    height: 50px;\n    border-radius: 2px;\n    border: solid 1px ", ";\n    background-color: ", ";\n    width: 100%;\n    padding: 0 17px;\n    font-size: 14px;\n    box-sizing: border-box;\n    color: ", ";\n    text-overflow: clip;\n    font-family: Ubuntu, sans-serif;\n    transition: 0.3s;\n\n    ::placeholder {\n      color: rgb(32, 32, 32, 0.5);\n    }\n\n    :invalid {\n      border-color: red;\n    }\n  }\n\n  /* Chrome, Safari, Edge, Opera */\n  input::-webkit-outer-spin-button,\n  input::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n  }\n\n  /* Firefox */\n  input[type='number'] {\n    -moz-appearance: textfield;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n\n  input {\n    height: 50px;\n    border-radius: 2px;\n    border: solid 1px ", ";\n    background-color: ", ";\n    width: 100%;\n    padding: 0 17px;\n    font-size: 14px;\n    box-sizing: border-box;\n    color: ", ";\n    text-overflow: clip;\n    font-family: Ubuntu, sans-serif;\n    transition: 0.3s;\n\n    ::placeholder {\n      color: rgb(32, 32, 32, 0.5);\n    }\n\n    :invalid {\n      border-color: red;\n    }\n  }\n\n  /* Chrome, Safari, Edge, Opera */\n  input::-webkit-outer-spin-button,\n  input::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n  }\n\n  /* Firefox */\n  input[type='number'] {\n    -moz-appearance: textfield;\n  }\n"])), function (props) { return (props.error ? 'red' : 'rgba(137, 137, 137, 0.3)'); }, function (props) { return (props.disabledStyle ? 'rgba(0,0,0,.05)' : 'white'); }, function (props) { return (props.disabledStyle ? '#898989' : 'inherit'); });
var FormInput = function (_a) {
    var label = _a.label, _b = _a.type, type = _b === void 0 ? 'text' : _b, name = _a.name, toolTip = _a.toolTip, control = _a.control, disabled = _a.disabled, placeholder = _a.placeholder, onChange = _a.onChange, value = _a.value, defaultValue = _a.defaultValue, _c = _a.isRequired, isRequired = _c === void 0 ? false : _c, _d = _a.readOnly, readOnly = _d === void 0 ? false : _d, additionalToTooltip = _a.additionalToTooltip, _e = _a.error, error = _e === void 0 ? false : _e;
    var handleChange = function (value) { return onChange === null || onChange === void 0 ? void 0 : onChange(value); };
    var getPattern = function (name) {
        if (name === 'postalCode')
            return '^([Gg][Ii][Rr] ?0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) ?[0-9][A-Za-z]{2})$';
        if (name === 'registrationNumber')
            return '(^[A-Za-z0-9]{2,7}$)|(^(?=.{3,8}$)[a-zA-Z0-9]+( [a-zA-Z0-9]+)$)';
        if (name === 'email')
            return '^(([a-zA-Z0-9])|([a-zA-Z0-9%_+-]{1,}[a-zA-Z0-9%._+-][a-z0-9%_+-]{1,}))+@(([a-zA-Z0-9%_+-])|([a-zA-Z0-9%_+-]{1,}[a-zA-Z0-9%._+-]{1,}[a-z0-9%_+-]{1,}))+.[a-z]{2,3}$';
        return undefined;
    };
    var getPatternRegEx = function (name) {
        if (name === 'postalCode')
            return /^([Gg][Ii][Rr] ?0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) ?[0-9][A-Za-z]{2})$/;
        if (name === 'registrationNumber')
            return /(^[A-Za-z0-9]{2,7}$)|(^(?=.{3,8}$)[a-zA-Z0-9]+( [a-zA-Z0-9]+)$)/;
        if (name === 'email')
            return /^(([a-zA-Z0-9])|([a-zA-Z0-9%_+-]{1,}[a-zA-Z0-9%._+-][a-z0-9%_+-]{1,}))+@(([a-zA-Z0-9%_+-])|([a-zA-Z0-9%_+-]{1,}[a-zA-Z0-9%._+-]{1,}[a-z0-9%_+-]{1,}))+.[a-z]{2,3}$/;
        return undefined;
    };
    var sortcodeAutoDashedInput = function (inputValue) {
        var value = inputValue;
        var removed = value.split('-').join('');
        var parts = removed.match(/.{1,2}/g);
        var dashed = parts === null || parts === void 0 ? void 0 : parts.join('-');
        return dashed !== undefined ? dashed : '';
    };
    var autoCapitalise = function (str) {
        return str.replace(/(?:^|\s)\S/g, function (match) {
            return match.toUpperCase();
        });
    };
    return (React.createElement(Root, { disabledStyle: readOnly, error: error },
        label && (React.createElement(FormLabel, { label: label, toolTip: toolTip, additionalToTooltip: additionalToTooltip, isRequired: isRequired })),
        control && (React.createElement("input", { type: type, name: name, ref: control({
                isRequired: isRequired,
                pattern: getPatternRegEx(name),
                validate: function (value) { return value !== ''; },
                maxLength: name === 'sortCode' ? 8 : 30,
            }), disabled: disabled, placeholder: placeholder, defaultValue: defaultValue, readOnly: readOnly, style: { textTransform: type !== 'email' && name !== 'sortCode' ? 'capitalize' : undefined }, maxLength: name === 'sortCode' ? 8 : undefined, onChange: name === 'sortCode'
                ? function (e) { return (e.target.value = sortcodeAutoDashedInput(e.target.value)); }
                : function (e) {
                    if (type !== 'email' && type !== 'number')
                        return (e.target.value = autoCapitalise(e.target.value));
                    return null;
                } })),
        !control && (React.createElement(React.Fragment, null,
            React.createElement("input", { type: type, name: name, disabled: disabled, placeholder: placeholder, onChange: function (e) { return handleChange(e.currentTarget.value); }, value: value, pattern: getPattern(name), style: { textTransform: type !== 'email' ? 'capitalize' : undefined } })))));
};
export default FormInput;
var templateObject_1;
