var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'components/atoms/Button';
import SectionHeader from 'components/molecules/SectionHeader';
import Layout, { PaddingHorizontal } from 'components/organisms/Layout';
import { FormRadio, Group } from '../Form';
import { FormControl } from '@material-ui/core';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var ButtonWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  grid-column: 1/-1;\n\n  button {\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  grid-column: 1/-1;\n\n  button {\n    margin-left: 10px;\n  }\n"])));
var content = {
    title: 'My Renewal Preferences',
    text: 'If you have opted for automatic renewal, we will renew your policy using the payment details you have previously given, provided your card/instalment details allow this. If you have set up an instalment plan, any automatic renewal will be onto this instalment plan instead of charging your card. \n\n We will only automatically renew your policy after issuing you with a renewal notice approximately 21 days before the end of your policy. If you do not want us to automatically renew your cover, or you would like to opt in to automatic renewal, please be aware you must do this more than 30 days before your renewal date. Any change made within 3 days of your policy renewal date will not be applied to the upcoming renewal. \n\n If we don’t hold your card details on file, you will receive an email and SMS to contact us to provide these during your renewal offer. \n\n If you are not the card or account holder you must ensure that the card/account holder has agreed to make the renewal payment and you must tell them of any changes to the amount. Your renewal payment will be taken from your account 3 days before the renewal date.\n\nIf you have opted for manual renewal and want to accept our renewal offer, you’ll need to do this in Your Portal or call us on 0333 103 0000 before your policy expires. If you don’t have a motor insurance policy in force and you use your car on public roads, you run the risk of prosecution.',
};
var MyRenewalPreferencesSection = function (_a) {
    var confirmRenewalChange = _a.confirmRenewalChange, selected = _a.selected, isRenewalWithin30Days = _a.isRenewalWithin30Days;
    var _b = useState(true), open = _b[0], setOpen = _b[1];
    var _c = useState(''), radioValue = _c[0], setRadioValue = _c[1];
    var title = content.title, text = content.text;
    React.useEffect(function () {
        setRadioValue(selected);
    }, [selected]);
    return (React.createElement(Layout, { shadow: true, withSeperator: true },
        React.createElement(PaddingHorizontal, null,
            React.createElement(Root, null,
                React.createElement(SectionHeader, { expandable: true, open: open, title: title, onClick: function () { return setOpen(!open); } }),
                open && (React.createElement(React.Fragment, null,
                    React.createElement(Group, { noBorder: true, introText: text },
                        React.createElement(FormControl, null,
                            React.createElement(FormRadio, { options: ['Opt in', 'Opt out'], value: radioValue, name: "name", handleUncontrolled: function (event) { return setRadioValue(event); }, disabled: isRenewalWithin30Days })),
                        React.createElement(ButtonWrapper, null,
                            React.createElement(Button, { buttonStyle: "secondary", onClick: function () {
                                    confirmRenewalChange(radioValue);
                                }, disabled: isRenewalWithin30Days }, 'Confirm change')))))))));
};
export default MyRenewalPreferencesSection;
var templateObject_1, templateObject_2;
